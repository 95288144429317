//jQuery
import axios from 'axios';

//jQuery

// ヘッダー固定
// mainnavi
$(function () {

  $(window).on('load scroll resize', function () {
    var scrollNum = 100;
    var winW = $(window).width();
    if (winW < 800) {
      scrollNum = 100;
    } else {
      scrollNum = 500;
    }
    if ($(this).scrollTop() > scrollNum) {
      $("#fixed-header").removeClass("translate-y-[-62px]")
      $("#fixed-header").addClass("translate-y-0")
    } else {
      $("#fixed-header").removeClass("translate-y-0")
      $("#fixed-header").addClass("translate-y-[-62px]")
    }

  });
});
// mainnavi

$(function () {
  $('.menu-btn').on('click', function () {
    if ($('span', this).hasClass('hbg_close')) {
      $('span', this).removeClass('hbg_close');
      $('#mainnavi').removeClass('navion');
      $('body').removeClass('navion_bg');
      $('html').removeClass('scroll-prevent');
    } else {
      $('span', this).addClass('hbg_close');
      $('#mainnavi').addClass('navion');
      $('body').addClass('navion_bg');
      $('html').addClass('scroll-prevent');
    }
  });
  $('main').on('click resize', function () {
    if ($('body').hasClass('navion_bg')) {
      $('body').removeClass('navion_bg');
      $('.menu-btn span').removeClass('hbg_close');
      $('#mainnavi').removeClass('navion');

    }
  });
  $(window).on('resize', function () {
    if ($(window).width() < 768) {
      if ($('body').hasClass('navion_bg')) {
        $('html').addClass('scroll-prevent');
      }
    } else {
      $('html').removeClass('scroll-prevent');
    }

  });
});
//footer-navi
$(function () {
  $(".f-nav-toggle").on("click", function () {
    $(this).toggleClass('toggle-on').next('ul').slideToggle();
  });
});
//tel-link
$(function () {
  var ua = navigator.userAgent;
  if (ua.indexOf('iPhone') > 0 || ua.indexOf('Android') > 0) {
    $('.tel-link').each(function () {
      var str = $(this).text();
      $(this).html($('<a>').attr('href', 'tel:' + str.replace(/-/g, '')).append(str + '</a>'));
    });
  }
});

//item-detail サムネイルクリックでメインの画像切り替え
$(function () {

  $('.item-main-movie').css({'opacity':'0','pointer-events':'none'});
  $('.item-thumb li').click(function () {

    const $thisImg = $('img',this).attr('src');
    const $thisAlt = $('img',this).attr('alt');

    const $movieThumb = $thisImg.split('/');
    const $movieId = ($movieThumb[$movieThumb.length - 2]);

      if ($(this).hasClass('movie-thumb-area')) {

      $('.item-main-img').css({'opacity':'0','pointer-events':'none'});
      $('.item-main-movie').css({'opacity':'1','pointer-events':'inherit'});
      
    } else {

      $('.item-main-movie').css({'opacity':'0','pointer-events':'none'});
      $('.item-main-img').css({'opacity':'1','pointer-events':'inherit'});

      $('.item-main-img img').attr({
        src: $thisImg,
        alt: $thisAlt
      });
    }

  });
});

//レビュー投稿エリア開閉
$(function () {
  $(".toggle-btn").on("click", function () {
    $(this).toggleClass('toggle-btn-on');
    $(this).next('.box-toggle').slideToggle().toggleClass('toggle-on');
  });
});
//関連商品SP fix
$(function () {

  $(window).on('load scroll resize', function () {
    var scrollNum = 600;
    var winW = $(window).width();
    if (winW < 768) {
      if ($(this).scrollTop() > scrollNum) {
        $('.relation-item-area').addClass('fixed');
      } else {
        $('.relation-item-area').removeClass('fixed');
      }
    } else {
      $('.relation-item-area').removeClass('fixed');
    }


  });
});

// 選択されているか判定
$(function () {
  $('[name="display-style"]').on('change', function () {

    var strVal = $('[name="display-style"]').val();
    if (strVal == "01") {
      $(this).toggleClass('select2');
    }
  });

});

//ranking 
//spの時に実行

var widthFlag = '';
$(function () {
  // 画面サイズのチェック
  $(window).on('load resize', function () {
    widthCheck();
  });
});

// 画面サイズのチェック
function widthCheck() {
  // 画面幅取得
  var winWidth = $(window).width();
  //  $('.ranking-select').find('dd').css('display', 'block');
  // 画面幅767以下でフラグがspでない時
  if (winWidth <= 767 && widthFlag != 'sp') {
    // フラグをSPに変更
    widthFlag = 'sp';
    $('.ranking-select').find('dd').css('display', 'none');
    $(".ranking-select dt").on("click", function () {
      $(this).next('dd').not(":animated").slideToggle();

      $(this).toggleClass('dd-open');
    });

    // 画面幅640よりおおきく、フラグがpcでない時
  } else if (winWidth > 768 && widthFlag != 'pc') {
    // フラグをPCに変更
    widthFlag = 'pc';
    $('.ranking-select dd').css('display', 'block');
  }
}

// 定期便 よくある質問
$(function () {
  $('.subscription__faq__q').each(function() {
    $(this).on('click', function() {
      $('+.subscription__faq__a', this).slideToggle();
      $(this).toggleClass('is-active');
      return false;
    });
  });

});
//
$(function () {
  var winWidth = $(window).width();
  var headerHight = 70; //ヘッダーの高さ
  var headerHightPC = 90; //ヘッダーの高さ
  $('a[href^="#log-"]').click(function () {
    
  var href = $(this).attr("href");
  var target = $(href == "#" || href == "" ? "html" : href);
  if (winWidth <= 767){
    var position = target.offset().top - headerHight;
  }else{
    var position = target.offset().top - headerHightPC;
  }
  $("html, body").animate({
  scrollTop: position
  }, 500, "swing");
  return false;
  
  });
});
$(function () {
  $('#premium-openModal').click(function(){
      $('#premium-modalArea').fadeIn();
  });
  $('#premium-closeModal , #premium-modalBg').click(function(){
    $('#premium-modalArea').fadeOut();
  });
});

// ハンバーガーメニュー
$(function () {
  // ハンバーガーメニューを開くボタンをクリックしたときの処理
  $('.open-modal-button').on('click', function () {
    $('body').addClass('modal-open');
    $('#modal').removeClass('hidden');
  });

  // ハンバーガーメニューを閉じるボタンをクリックしたときの処理
  $('#close-modal-button').on('click', function () {
    $('body').removeClass('modal-open');
    $('#modal').addClass('hidden');
  });

  // PC-ハンバーガーメニューを開くボタンをクリックしたときの処理
  $('.pc-open-modal-button').on('click', function () {
    $(this).toggleClass('close-btn');
    $('body').addClass('pc-modal-open');
    $('#mainnavi').stop().fadeToggle();
    $('.mainnavi-overlay').stop().fadeToggle();
  });

  // PC-ハンバーガーメニューを開くボタンをクリックしたときの処理
  $('.mainnavi-overlay').on('click', function () {
    $('.pc-open-modal-button').removeClass('close-btn');
    $('body').removeClass('pc-modal-open');
    $('#mainnavi').stop().fadeOut();
    $('.mainnavi-overlay').stop().fadeOut();
  });
});

// その他の条件から探すモーダル
$(function () {
  $('#open-search-modal-button').on('click', function () {
    $('body').addClass('modal-open');
    $('#search-modal').removeClass('hidden');
  });

  $('#close-search-modal-button').on('click', function () {
    $('body').removeClass('modal-open');
    $('#search-modal').addClass('hidden');
  });
});

// TOP レビューから探すタブ
$(function () {
  $("#review-tab").on("click", function () {
    $("#search-review-tab-content").removeClass("hidden")
    $("#search-reviewer-tab-content").addClass("hidden")

    // レビューワタブボタンの背景を白にして、レビュータブボタンの背景をグレーにする
    $("#reviewer-tab").removeClass("bg-[#F5F4F1] font-semibold")
    $("#reviewer-tab").addClass("bg-white font-medium")
    $("#review-tab").removeClass("bg-white font-medium")
    $("#review-tab").addClass("bg-[#F5F4F1] font-semibold")
  })

  // レビュワータブに切り替え
  $("#reviewer-tab").on("click", function () {
    $("#search-reviewer-tab-content").removeClass("hidden")
    $("#search-review-tab-content").addClass("hidden")

    // レビュータブボタンの背景を白にして、レビュワータブボタンの背景をグレーにする
    $("#review-tab").removeClass("bg-[#F5F4F1] font-semibold")
    $("#review-tab").addClass("bg-white font-medium")
    $("#reviewer-tab").removeClass("bg-white font-medium")
    $("#reviewer-tab").addClass("bg-[#F5F4F1] font-semibold")
  })
})

// いいねボタン
$(function () {
  $(".add-like-button").on("click", function () {
    var userReviewId = $(this).data("user-review-id");
    var url = "/api/user_review_likes";
    var data = {
      user_review_id: userReviewId
    };  

    $.ajax({
      type: "POST",
      url: url,
      data: data,
      dataType: "json", 
      success: function (data) {
        $("#add-like-button-" + userReviewId).addClass("hidden");
        $("#remove-like-button-" + userReviewId).removeClass("hidden");

        var userReviewLikesCount = $("#user-review-likes-count-" + userReviewId);
        var count = data.count.toLocaleString();
        userReviewLikesCount.text(count);
      }
    });
  });

  $(".remove-like-button").on("click", function () {
    var userReviewId = $(this).data("user-review-id");
    var url = "/api/user_review_likes/" +  userReviewId;

    $.ajax({
      type: "DELETE",
      url: url,
      dataType: "json",
      success: function (data) {
        $("#remove-like-button-" + userReviewId).addClass("hidden");
        $("#add-like-button-" + userReviewId).removeClass("hidden");

        var userReviewLikesCount = $("#user-review-likes-count-" + userReviewId);
        var count = data.count.toLocaleString();
        userReviewLikesCount.text(count);
      }
    });
  });
  
  $(".like-button-need-login").on("click", function () {
    alert('役に立ったボタンを押すためには、会員登録およびログインが必要です');
  });
});

$(function () {
  $(".cart-in-button").on("click", function (e) {
    var productId = $(this).data("product-id");
    addByGet(productId);
    return false;    
  });

  function addByGet(productId) {    
    axios
      .get(`/cart/${productId}/add.json`)
      .then(response =>{
        if(response.data.success === true){
          var notyf = new Notyf({
            position: {
              x: 'right',
              y: 'bottom',
            },
          });
          notyf.success('カート追加が完了しました！');
          const cartNums = document.getElementsByClassName('cart_num');
          for (let i = 0; i < cartNums.length; i++) {          
            cartNums[i].textContent = response.data.cart_nums;
          }
        }else{
          var notyf = new Notyf({
            position: {
              x: 'right',
              y: 'bottom',
            },
          });
          notyf.error(response.data.message);      
        }
    });    
  
    return false;
  }

  $('.favorite-btn-off-unlogin').on('click', function () {
    alert('お気に入り登録には、会員登録およびログインが必要です！');
  });

  $('.notice-btn-off-unlogin').on('click', function () {
    alert('入荷通知登録には、会員登録およびログインが必要です！');
  });

  $('.favorite-btn-off').on('click', function () {    
    var productId = $(this).data("product-id");
    axios
      .post('/favorites.json', {product_id: productId})
      .then(response =>{
      alert(`お気に入り登録が完了しました！`);
    });    

    // classをfavorite-btn-onに変更
    $(this).removeClass('favorite-btn-off');
    $(this).addClass('favorite-btn-on');

    // 画像を"media/images/common/btn-favorite-on.svg"に差し替え
    this.src = $(this).data("on-image-path");
  });

  $('.notice-btn-off').on('click', function () {
    var productId = $(this).data("product-id");
    axios
      .post(`/products/${productId}/notify.json`)
      .then(response =>{
      alert(`入荷通知登録が完了しました！`);
    }); 

    // classをnotice-btn-onに変更
    $(this).removeClass('notice-btn-off');
    $(this).addClass('notice-btn-on');

    // 画像を"media/images/common/btn-favorite-on.svg"に差し替え
    this.src = $(this).data("on-image-path");
  });
});